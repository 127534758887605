<section id="chronologie" class="mt-5">
  <h1 class="title" translate="CHRONOLOGY"></h1>
  <div class="timeline is-centered">
    <header class="timeline-header">
      <span class="tag is-medium is-info">{{'TODAY' | translate}}</span>
    </header>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='briefcase' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'JULY' | translate }} 2024</p>
        <p *ngIf="utils.isActiveLanguageFrench" class="cursor-hoverable" (click)="utils.goToTarget('experiences')">Début de mon stage à l'étranger chez <a href="https://www.leroymerlin.pt/" target="_blank">Leroy Merlin Portugal </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish" class="cursor-hoverable" (click)="utils.goToTarget('experiences')">Beginning of my internship abroad at <a href="https://www.leroymerlin.pt/" target="_blank">Leroy Merlin Portugal </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
      </div>
    </div>
    <header class="timeline-header">
      <span class="tag is-info">2024</span>
    </header>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='briefcase' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'SEPTEMBER' | translate }} 2022</p>
        <p *ngIf="utils.isActiveLanguageFrench" class="cursor-hoverable" (click)="utils.goToTarget('experiences')">Début de mon alternance entre l'<a href="https://imt-nord-europe.fr/" target="_blank">IMT Nord Europe </a> et <a href="https://www.leroymerlin.fr/" target="_blank">Leroy Merlin </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish" class="cursor-hoverable" (click)="utils.goToTarget('experiences')">Beginning of my apprenticeship between <a href="https://imt-nord-europe.fr/" target="_blank">IMT Nord Europe </a> and <a href="https://www.leroymerlin.fr/" target="_blank">Leroy Merlin </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
      </div>
    </div>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='graduation' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'JULY' | translate }} 2022</p>
        <p *ngIf="utils.isActiveLanguageFrench">DUT Informatique à l'<a href="https://www.iut-a.univ-lille.fr/" target="_blank">IUT A de Lille </a><span class="has-text-grey text-small">({{'END' | translate}})</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish">Computer Science Degree at <a href="https://www.iut-a.univ-lille.fr/" target="_blank">IUT A of Lille </a><span class="has-text-grey text-small">({{'END' | translate}})</span></p>
      </div>
    </div>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='building' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'JUNE' | translate }} 2022</p>
        <p class="cursor-hoverable" *ngIf="utils.isActiveLanguageFrench" (click)="utils.goToTarget('experiences')">Stage de développement web à <a href="https://www.norsys.fr/" target="_blank">norsys </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
        <p class="cursor-hoverable" *ngIf="utils.isActiveLanguageEnglish" (click)="utils.goToTarget('experiences')">Web development internship at <a href="https://www.norsys.fr/" target="_blank">norsys </a><span class="has-text-grey text-small">({{'END' | translate}})</span></p>
      </div>
    </div>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='building' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'APRIL' | translate }} 2022</p>
        <p class="cursor-hoverable" *ngIf="utils.isActiveLanguageFrench" (click)="utils.goToTarget('experiences')">Stage de développement web à <a href="https://www.norsys.fr/" target="_blank">norsys </a><span class="has-text-grey text-small">({{'START' | translate}})</span>
        <p class="cursor-hoverable" *ngIf="utils.isActiveLanguageEnglish" (click)="utils.goToTarget('experiences')">Web development internship at <a href="https://www.norsys.fr/" target="_blank">norsys </a><span class="has-text-grey text-small">({{'START' | translate}})</span></p>
      </div>
    </div>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='briefcase' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'MARCH' | translate }} 2022</p>
        <p *ngIf="utils.isActiveLanguageFrench">Job étudiant à <a>LIDL </a> <span class="has-text-grey text-small">({{'END' | translate }})</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish">Student job at <a>LIDL </a> <span class="has-text-grey text-small">({{'END' | translate }})</span></p>
      </div>
    </div>
    <header class="timeline-header">
      <span class="tag is-info">2022</span>
    </header>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='briefcase' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'DECEMBER' | translate }} 2021</p>
        <p *ngIf="utils.isActiveLanguageFrench">Job étudiant à <a>LIDL </a><span class="has-text-grey text-small">({{'START' | translate }})</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish">Student job at <a>LIDL </a><span class="has-text-grey text-small">({{'START' | translate }})</span></p>
      </div>
    </div>
    <header class="timeline-header">
      <span class="tag is-info">2021</span>
    </header>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='graduation' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'SEPTEMBER' | translate}} 2020</p>
        <p *ngIf="utils.isActiveLanguageFrench">DUT Informatique à l'<a href="https://www.iut-a.univ-lille.fr/" target="_blank">IUT A de Lille </a><span class="has-text-grey text-small">(début)</span></p>
        <p *ngIf="utils.isActiveLanguageEnglish">Computer Science Degree at <a href="https://www.iut-a.univ-lille.fr/" target="_blank">IUT A of Lille </a><span class="has-text-grey text-small">({{'START' | translate }})</span></p>
      </div>
    </div>
    <div class="timeline-item is-info">
      <div class="timeline-marker is-info is-image is-32x32 is-flex is-justify-content-space-around is-align-items-center">
        <box-icon type='solid' size="20px" name='graduation' color="white"></box-icon>
      </div>
      <div class="timeline-content">
        <p class="heading">{{'JULY' | translate}} 2020</p>
        <p *ngIf="utils.isActiveLanguageFrench">Baccalauréat Scientifique (option ISN) au <a href="https://marguerite-de-flandre-gondecourt.enthdf.fr/" target="_blank">Lycée Marguerite de Flandre</a></p>
        <p *ngIf="utils.isActiveLanguageEnglish">Baccalauréat Scientifique (Information and Digital Science option) at <a href="https://marguerite-de-flandre-gondecourt.enthdf.fr/" target="_blank">Lycée Marguerite de Flandre</a></p>
      </div>
    </div>
    <header class="timeline-header">
      <span class="tag is-medium is-info">2020</span>
    </header>
  </div>
</section>
