import {inject, Injectable, OnInit} from '@angular/core';
import {init, send} from "@emailjs/browser";
import {NotifService} from "./notif.service";

export interface ContactFormData {
  email: string;
  subject: string;
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailService {
  MAX_MAIL_BY_SESSION = 3;

  mailSent: number = 0;

  async sendContactMail(data: ContactFormData) {
    init('FkaqQwFR9FY3sj62_');
    if (this.mailSent >= this.MAX_MAIL_BY_SESSION) {
      return Promise.reject('Too many mails sent');
    }
    this.mailSent++;
    return send('service_l40cqlq', 'template_ohaxmbx', {
      email: data.email,
      subject: data.subject,
      message: data.content,
    });
  }
}
