<div class="image-slider">
  <img [src]="images[currentIndex]" alt="Image slider">
  <button class="arrow left-arrow" (click)="previousImage()">
    <i class="fa fa-chevron-left"></i>
  </button>
  <button class="arrow right-arrow" (click)="nextImage()">
    <i class="fa fa-chevron-right"></i>
  </button>
  <div class="indicator-container">
          <span *ngFor="let image of images; let i = index"
                class="indicator"
                [class.active]="i === currentIndex"
                (click)="setCurrentIndex(i)"></span>
  </div>
</div>
