import {Component, ElementRef, HostListener, inject, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {UtilsService} from "../../shared/utils.service";
import {ContactModalComponent} from "../modals/contact/contact-modal.component";
import {ContactChoiceComponent} from "../modals/contact-choice/contact-choice.component";
import {NotifService} from "../../services/notif.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  dialog = inject(MatDialog);
  utils = inject(UtilsService);
  notif = inject(NotifService);

  @ViewChild('langDropdown') langDropdown!: ElementRef;

  setLanguage(lang: string) {
    this.utils.changeLanguage(lang);
    this.langDropdown.nativeElement.classList.remove('is-active');
  }


  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.langDropdown.nativeElement.contains(event.target)) {
      this.langDropdown.nativeElement.classList.remove('is-active');
    }
  }

  openContactModal() {
    this.dialog.open(ContactChoiceComponent);
  }

}
