import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition, query, animateChild, group } from '@angular/animations';

@Component({
  selector: 'image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, OnDestroy {
  @Input({required: true}) images: string[] = [];
  @Input({required: false}) transition: boolean = true;
  @Input({required: false}) transitionTime: number = 3000;

  currentIndex = 0;
  intervalId: any;

  ngOnInit() {
    if(this.transition) {
      this.startAutoTransition();
    }
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startAutoTransition() {
    this.intervalId = setInterval(() => this.nextImage(), this.transitionTime);
  }

  getImagesLength(): number {
    return this.images?.length || 0;
  }

  previousImage() {
    this.currentIndex = (this.currentIndex + this.getImagesLength() - 1) % this.getImagesLength();
    this.resetAutoTransition();
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.getImagesLength();
    this.resetAutoTransition();
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
    this.resetAutoTransition();
  }

  resetAutoTransition() {
    clearInterval(this.intervalId);
    this.startAutoTransition();
  }
}
