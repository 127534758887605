import {Component, Inject, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-notif', templateUrl: './notif.component.html', styleUrls: ['./notif.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotifComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<NotifComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit() {
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
