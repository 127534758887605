<footer class="footer">
  <div class="content has-text-centered">
    <p *ngIf="utils.isActiveLanguageFrench">
      Site réalisé avec
      <a href="https://angular.io/" target="_blank" class="has-text-danger has-text-weight-bold">Angular</a>,
      <a href="https://bulma.io/" target="_blank" class="has-text-primary has-text-weight-bold">Bulma</a> et
      <a href="https://boxicons.com/" target="_blank" class="has-text-dark has-text-weight-bold">Boxicons</a>
      par <strong>Théo ERNOULD</strong>
    </p>
    <p *ngIf="utils.isActiveLanguageEnglish">
      Website made with
      <a href="https://angular.io/" target="_blank" class="has-text-danger has-text-weight-bold">Angular</a>,
      <a href="https://bulma.io/" target="_blank" class="has-text-primary has-text-weight-bold">Bulma</a> and
      <a href="https://boxicons.com/" target="_blank" class="has-text-dark has-text-weight-bold">Boxicons</a>
      by <strong>Théo ERNOULD</strong>
    </p>
  </div>
</footer>
