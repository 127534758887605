import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotifService } from "../../../services/notif.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MailService} from "../../../services/mail.service";
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent {
  form: FormGroup;
  isSendingMail: boolean = false;

  constructor(public dialogRef: MatDialogRef<ContactModalComponent>,
              public mailService: MailService,
              public utils: UtilsService,
              public notifService: NotifService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  validate() {
    if (this.form.valid) {
      const formData = this.form.value;
      this.mailService.sendContactMail(formData).then(r => {
        //
        this.notifService.sendNotif('MESSAGE_SENT', 'success');
      }).catch(e => {
        this.notifService.sendNotif("ERROR_WHILE_SENDING_MESSAGE", 'danger');
      }).finally(() => {
        this.isSendingMail = false;
        this.onClose();
      });
      this.isSendingMail = true;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
