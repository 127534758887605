<section id="competences" class="mt-5" [class.mobile-template]="utils.isMobile">
  <h1 class="title" translate="SKILLS"></h1>
  <h2 class="subtitle mt-3" translate="LANGUAGES"></h2>
  <article>
    <app-competence-bloc title="Java" imageUrl="assets/logos/java.png"></app-competence-bloc>
    <app-competence-bloc title="Javascript" imageUrl="assets/logos/js.png"></app-competence-bloc>
    <app-competence-bloc title="Typescript" imageUrl="assets/logos/typescript.png"></app-competence-bloc>
    <app-competence-bloc title="HTML" imageUrl="assets/logos/html.png"></app-competence-bloc>
    <app-competence-bloc title="CSS" imageUrl="assets/logos/css.png"></app-competence-bloc>
    <app-competence-bloc title="SCSS" imageUrl="assets/logos/sass.png"></app-competence-bloc>
  </article>
  <h2 class="subtitle mt-3" translate="FRAMEWORKS"></h2>
  <article>
    <app-competence-bloc title="Spring" imageUrl="assets/logos/spring-logo.png" ribbon="Back" ribbonColor="is-link"></app-competence-bloc>
    <app-competence-bloc title="Vue.js" imageUrl="assets/logos/vuejs.png" ribbon="Front"></app-competence-bloc>
    <app-competence-bloc title="Angular" imageUrl="assets/logos/angular.png" ribbon="Front"></app-competence-bloc>
    <app-competence-bloc title="Angular Material" imageUrl="assets/logos/angular_material.png" ribbon="Front"></app-competence-bloc>
    <app-competence-bloc title="Bulma" imageUrl="assets/logos/bulma.png" ribbon="Front"></app-competence-bloc>
  </article>
  <h2 class="subtitle mt-3" translate="DATABASES"></h2>
  <article>
    <app-competence-bloc title="MongoDB" imageUrl="assets/logos/mongodb.svg"></app-competence-bloc>
    <app-competence-bloc title="Posgresql" imageUrl="assets/logos/posgresql.png"></app-competence-bloc>
  </article>
  <h2 class="subtitle mt-3" translate="TOOLS"></h2>
  <article>
    <app-competence-bloc title="Maven" imageUrl="assets/logos/maven.png"></app-competence-bloc>
    <app-competence-bloc title="Sonarqube" imageUrl="assets/logos/sonarqube.svg"></app-competence-bloc>
    <app-competence-bloc title="Postman" imageUrl="assets/logos/postman.svg"></app-competence-bloc>
    <app-competence-bloc title="Jira" imageUrl="assets/logos/jira.png"></app-competence-bloc>
    <app-competence-bloc title="Github" imageUrl="assets/logos/github.png"></app-competence-bloc>
    <app-competence-bloc title="Gitlab" imageUrl="assets/logos/gitlab.png"></app-competence-bloc>
  </article>
  <h2 class="subtitle mt-3" translate="CODE_EDITORS"></h2>
  <article>
    <app-competence-bloc title="Intellij" imageUrl="assets/logos/intellij.png"></app-competence-bloc>
    <app-competence-bloc title="Eclipse" imageUrl="assets/logos/eclipse.png"></app-competence-bloc>
    <app-competence-bloc title="Visual Studio Code" imageUrl="assets/logos/vscode.png"></app-competence-bloc>
  </article>
</section>
