import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { AProposDesktopComponent } from './components/sections/a-propos/a-propos-desktop/a-propos-desktop.component';
import { HistoriqueComponent } from './components/sections/historique/historique.component';
import { ProjetsComponent } from './components/sections/projets/projets.component';
import { CertificationsComponent } from './components/sections/certifications/certifications.component';
import { CompetencesComponent } from './components/sections/competences/competences.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from "@angular/material/dialog";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule, Routes} from "@angular/router";
import { CompetenceBlocComponent } from './components/sections/competences/competence-bloc/competence-bloc.component';
import { ExperiencesComponent } from './components/sections/experiences/experiences.component';
import { ExperienceComponent } from './components/sections/experiences/experience/experience.component';
import { ProjetComponent } from './components/sections/projets/projet/projet.component';
import {AProposMobileComponent} from "./components/sections/a-propos/a-propos-mobile/a-propos-mobile.component";
import {AProposComponent} from "./components/sections/a-propos/a-propos.component";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { ThemeSwitcherComponent } from './shared/theme-switcher/theme-switcher.component';
import { ContactModalComponent } from './components/modals/contact/contact-modal.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ContactChoiceComponent } from './components/modals/contact-choice/contact-choice.component';
import { NotifComponent } from './services/notif/notif.component';
import { ImageSliderComponent } from './shared/image-slider/image-slider.component';

const routes: Routes = [
  { path: '', component: ContentComponent },
  { path: '**', redirectTo: '/'}
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('fr');
    const browserLang = translate.getBrowserLang();
    translate.use((browserLang?.match(/en|fr|de/) ? browserLang : 'en'));
    return translate.use('fr').toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    AProposComponent,
    AProposDesktopComponent,
    AProposMobileComponent,
    HistoriqueComponent,
    ProjetsComponent,
    CertificationsComponent,
    CompetencesComponent,
    CompetenceBlocComponent,
    ExperiencesComponent,
    ExperienceComponent,
    ProjetComponent,
    ThemeSwitcherComponent,
    ContactModalComponent,
    ContactChoiceComponent,
    NotifComponent,
    ImageSliderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2000}}
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
