import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotifComponent} from "./notif/notif.component";
import {TranslateService} from "@ngx-translate/core";

export type NotifType = 'success' | 'danger' | 'warning' | 'info';

@Injectable({
  providedIn: 'root'
})
export class NotifService {
  snackbarService = inject(MatSnackBar);
  translate = inject(TranslateService);

  sendNotif(messageKey: string, type: NotifType) {
    this.snackbarService.openFromComponent(NotifComponent, {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: 'blue-snackbar',
      duration: 2500,
      data: {
        type,
        message: this.translate.instant(messageKey)
      }
    });
  }
}
