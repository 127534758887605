<div id="apropos" class="card has-background-info has-text-white">
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <figure id="photo" class="image is-128x128">
          <img src="../../../../assets/photo.jpg" alt="Photo de Théo ERNOULD">
        </figure>
      </div>
      <div class="media-content">
        <div>
          <h1 class="title has-text-white has-text-weight-bold">Théo Ernould</h1>
          <h2 class="subtitle has-text-white" translate="ABOUT_ME.JOB"></h2>
          <h2 class="has-text-white" translate="ABOUT_ME.DESC"></h2>
        </div>
        <nav class="level mt-5">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">LinkedIn</p>
              <a href="https://www.linkedin.com/in/theo-ernould/" target="_blank">
                <box-icon type='logo' size='50px' color="white" name='linkedin-square'></box-icon>
              </a>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">GitHub</p>
              <a href="https://github.com/theoernould" target="_blank">
                <box-icon type='logo' size='50px' color="white" name='github'></box-icon>
              </a>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Gitlab</p>
              <a href="https://gitlab.com/theoernould" target="_blank">
                <box-icon type='logo' size='50px' color="white" name='gitlab'></box-icon>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="content mt-5">
      <nav class="level">
        <div class="level-item">
          <div>
            <p class="heading" translate="ABOUT_ME.STUDENT"></p>
            <a href="https://imt-nord-europe.fr/" target="_blank" class="title has-text-white">IMT Nord Europe</a>
          </div>
        </div>
        <div class="level-item">
          <div>
            <p class="heading" translate="ABOUT_ME.APPRENTICE"></p>
            <a href="https://www.leroymerlin.fr/" target="_blank" class="title has-text-white">Leroy Merlin</a>
          </div>
        </div>
        <div class="level-item">
          <div>
            <p class="heading" translate="ABOUT_ME.ADDRESS"></p>
            <a href="https://maps.app.goo.gl/2bweLB9YWvMUexuX6" target="_blank" class="title has-text-white">Lille</a>
          </div>
        </div>
        <div class="level-item">
          <div>
            <p class="heading" translate="ABOUT_ME.AGE"></p>
            <p class="title has-text-white">{{utils.getMyAge()}} {{ 'YEARS' | translate }}</p>
          </div>
        </div>
        <div class="level-item">
          <div>
            <p class="heading" translate="ABOUT_ME.VEHICLE"></p>
            <p class="title has-text-white" translate="YES"></p>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <footer class="card-footer">
    <div class="card-footer-item" id="CV_BUTTON" (click)="openCV.emit()">
      <span class="has-text-white has-text-weight-bold" translate="ABOUT_ME.DOWNLOAD_CV"></span>
      <box-icon type='solid' color="white" name='user-circle'></box-icon>
    </div>
  </footer>
</div>
