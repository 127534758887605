import {Component, inject} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {Experience} from "../../../models/experience";

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent {
  utils = inject(UtilsService);

  frenchExperiences = [
    {
      id: 'leroymerlinfullstack',
      poste: 'Développeur Full Stack en alternance',
      dates: 'Septembre 2022 - Aujourd\'hui',
      entreprise: 'Leroy Merlin - IMT Nord Europe',
      missions: [
        {
          id: 'wiser',
          titre: 'Wiser',
          dates: 'Septembre 2023 - Aujourd\'hui',
          description: 'Développement d\'une application interne, appelée Wiser, qui est un site web responsive. Elle permet aux responsables d\'offre et de rayon de consulter puis appliquer ou refuser des recommandations de produit, récupérées régulièrement à partir d\'un outil de machine learning. ',
          technologiesUsed: {
            langages: ['Java', 'TypeScript', 'Gherkin'],
            frameworks: ['Spring Boot', 'Vue.js 3', 'Mozaic', 'Tailwind CSS', 'Playwright', 'Cucumber'],
            outils: ['Maven', 'Github', 'Jira', 'MongoDB', 'SonarQube', 'Postman'],
            editeurs: ['IntelliJ Ultimate', 'Visual Studio Code'],
            autres: ['Scrum', 'Kanban', 'BDD (Behavior Driven Development)', 'TDD (Test Driven Development)']
          }
        },
        {
          id: 'sosdata',
          titre: 'SOS Data',
          dates: 'Septembre 2022 - Septembre 2023',
          description: 'Amélioration d\'un outil interne à Leroy Merlin qui s’appelle SOS Data dont le but est de servir d’interface pour remonter les erreurs descriptives présentes sur les produits hors marketplace, ces derniers pouvant ensuite être traitées par des équipes spécialisées. L’application est composée d’une API Rest développée avec Spring Boot pour la partie serveur et d\'un site web conçu avec Angular.',
          technologiesUsed: {
            langages: ['Java', 'TypeScript'],
            frameworks: ['Spring Boot', 'Angular', 'Angular Material', 'Mozaic'],
            outils: ['Maven', 'Github', 'Jira', 'MongoDB', 'SonarQube', 'Postman'],
            editeurs: ['IntelliJ Ultimate'],
            autres: ['Scrum']
          }
        }
      ]
    },
    {
      id: 'norsysweb',
      poste: 'Stagiaire en développement web',
      dates: 'Avril 2022 - Juin 2022',
      entreprise: 'norsys',
      missions: [
        {
          id: 'vestiairesolidaire',
          titre: 'Vestiaire Solidaire',
          dates: 'Avril 2022 - Juin 2022',
          description: 'Amélioration d’un site web de gestion de stocks pour l’association « Le Vestiaire Solidaire » basée à Marcq-en-Barœul dont l’objectif est de collecter et redistribuer des vêtements pour les personnes dans le besoin. L’interface a été conçue avec l’aide d’Angular et le serveur en Java avec Spring Boot.',
          technologiesUsed: {
            langages: ['Java', 'TypeScript'],
            frameworks: ['Spring Boot', 'Angular'],
            outils: ['Maven', 'Gitlab', 'Jira', 'PostgreSQL', 'Postman'],
            editeurs: ['IntelliJ Ultimate'],
            autres: ['Scrum']
          }
        }
      ]
    }
  ];
  englishExperiences = [
    {
      id: 'leroymerlinfullstack',
      poste: 'Full Stack Developer in apprenticeship',
      dates: 'September 2022 - Today',
      entreprise: 'Leroy Merlin - IMT Nord Europe',
      missions: [
        {
          id: 'wiser',
          titre: 'Wiser',
          dates: 'September 2023 - Today',
          description: 'Development of an internal application, called Wiser, which is a responsive website. It allows offer and department managers to consult and then apply or refuse product recommendations, regularly retrieved from a machine learning tool.',
          technologiesUsed: {
            langages: ['Java', 'TypeScript', 'Gherkin'],
            frameworks: ['Spring Boot', 'Vue.js 3', 'Mozaic', 'Tailwind CSS', 'Playwright', 'Cucumber'],
            outils: ['Maven', 'Github', 'Jira', 'MongoDB', 'SonarQube', 'Postman'],
            editeurs: ['IntelliJ Ultimate', 'Visual Studio Code'],
            autres: ['Scrum', 'Kanban', 'BDD (Behavior Driven Development)', 'TDD (Test Driven Development)']
          }
        },
        {
          id: 'sosdata',
          titre: 'SOS Data',
          dates: 'September 2022 - September 2023',
          description: 'Improvement of an internal tool at Leroy Merlin called SOS Data whose purpose is to serve as an interface to report descriptive errors on products outside the marketplace, which can then be processed by specialized teams. The application consists of a Rest API developed with Spring Boot for the server part and a website designed with Angular.',
          technologiesUsed: {
            langages: ['Java', 'TypeScript'],
            frameworks: ['Spring Boot', 'Angular', 'Angular Material'],
            outils: ['Maven', 'Github', 'Jira', 'MongoDB', 'SonarQube', 'Postman'],
            editeurs: ['IntelliJ Ultimate'],
            autres: ['Scrum']
          }
        }
      ]
    },
    {
      id: 'norsysweb',
      poste: 'Web development internship',
      dates: 'April 2022 - June 2022',
      entreprise: 'norsys',
      missions: [
        {
          id: 'vestiairesolidaire',
          titre: 'Vestiaire Solidaire',
          dates: 'April 2022 - June 2022',
          description: 'Improvement of a website for stock management for the association "Le Vestiaire Solidaire" based in Marcq-en-Barœul whose objective is to collect and redistribute clothes for people in need. The interface was designed with the help of Angular and the server in Java with Spring Boot.',
          technologiesUsed: {
            langages: ['Java', 'TypeScript'],
            frameworks: ['Spring Boot', 'Angular', 'Angular Material'],
            outils: ['Maven', 'Github', 'Jira', 'MongoDB', 'SonarQube', 'Postman'],
            editeurs: ['IntelliJ Ultimate'],
            autres: ['Scrum']
          }
        }
      ]
    }
  ];

  get experiences(): Experience[] {
    return this.utils.isActiveLanguageFrench ? this.frenchExperiences : this.englishExperiences;
  }

}
