<div class="content">
  <div class="container">
    <nav aria-label="main navigation" class="navbar" role="navigation">
      <div class="navbar-brand mobile-container">
        <a class="navbar-item my-name" routerLink="/">
          <span>Théo</span>
          <strong class="has-text-info">ERNOULD</strong>
        </a>
        <div class="mobile-actions">
          <div class="dropdown" #langDropdown *ngIf="utils.isMobile">
          <div class="dropdown-trigger" (click)="langDropdown.classList.toggle('is-active')">
            <button aria-controls="dropdown-menu2" aria-haspopup="true" class="button">
              <span class="fi fi-fr" *ngIf="utils.getActiveLanguage() == 'fr'"></span>
              <span class="fi fi-gb" *ngIf="utils.getActiveLanguage() == 'en'"></span>
              <span class="icon is-small">
                      <i aria-hidden="true" class="fas fa-angle-down"></i>
                    </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu2" role="menu">
            <div class="dropdown-content">
              <a class="dropdown-item" (click)="setLanguage('fr')">
                <div>
                  <span class="fi fi-fr"></span>
                  <span class="has-text-weight-bold">FR</span>
                </div>
              </a>
              <hr class="dropdown-divider">
              <a class="dropdown-item" (click)="setLanguage('en')">
                <div>
                  <span class="fi fi-gb"></span>
                  <span class="has-text-weight-bold">EN</span>
                </div>
              </a>
            </div>
          </div>
        </div>
          <!--        <theme-switcher class="theme-switcher" *ngIf="utils.isMobile"></theme-switcher>-->
          <div class="button is-info" *ngIf="utils.isMobile" (click)="openContactModal()">
            <i class='bx bxs-envelope'></i>
          </div>
        </div>
      </div>

      <div class="navbar-menu ml-3">
        <div class="navbar-start">
          <a (click)="utils.goToTarget('apropos')" class="navbar-item" translate="TOP_BAR.ABOUT_ME"></a>
          <a (click)="utils.goToTarget('chronologie')" class="navbar-item" translate="CHRONOLOGY"></a>
          <a (click)="utils.goToTarget('certifications')" class="navbar-item" translate="CERTIFICATES"></a>
          <a (click)="utils.goToTarget('experiences')" class="navbar-item" translate="EXPERIENCES"></a>
          <a (click)="utils.goToTarget('competences')" class="navbar-item" translate="SKILLS"></a>
          <a (click)="utils.goToTarget('projets')" class="navbar-item" translate="PROJECTS"></a>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <div class="dropdown" #langDropdown>
                <div class="dropdown-trigger" (click)="langDropdown.classList.toggle('is-active')">
                  <button aria-controls="dropdown-menu2" aria-haspopup="true" class="button">
                    <span class="fi fi-fr" *ngIf="utils.getActiveLanguage() == 'fr'"></span>
                    <span class="fi fi-gb" *ngIf="utils.getActiveLanguage() == 'en'"></span>
                    <span class="icon is-small">
                      <i aria-hidden="true" class="fas fa-angle-down"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item" (click)="setLanguage('fr')">
                      <div>
                        <span class="fi fi-fr"></span>
                        <span class="has-text-weight-bold">FR</span>
                      </div>
                    </a>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item" (click)="setLanguage('en')">
                      <div>
                        <span class="fi fi-gb"></span>
                        <span class="has-text-weight-bold">EN</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="button is-info" (click)="openContactModal()">
                <strong translate="CONTACT_ME"></strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>

</div>
