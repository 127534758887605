import {Component, EventEmitter, inject, Output} from '@angular/core';
import {UtilsService} from "../../../../shared/utils.service";

@Component({
  selector: 'app-a-propos-mobile',
  templateUrl: './a-propos-mobile.component.html',
  styleUrls: ['./a-propos-mobile.component.scss']
})
export class AProposMobileComponent {
  @Output() openCV = new EventEmitter();

  public utils = inject(UtilsService);
}
