<div class="modal is-active" [class.mobile]="utils.isMobile">
  <div class="modal-background" (click)="onClose()"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-content">
        <a class="subtitle has-text-weight-bold" translate="HOW_TO_CONTACT_ME"></a>
        <div [class.mobile]="utils.isMobile">
          <div class="button is-info" (click)="openFormModal()">
            <div>
              <strong translate="FORM"></strong>
            </div>
            <div>
              <i class='bx bxs-detail'></i>
            </div>
          </div>
          <div class="button is-info" (click)="openMailModal()">
            <div>
              <strong>Mail</strong>
            </div>
            <div>
              <i class='bx bxs-envelope'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
