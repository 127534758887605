<div class="modal is-active" [class.mobile]="utils.isMobile">
  <div class="modal-background" (click)="onClose()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title" translate="CONTACT_ME"></p>
      <button class="delete" aria-label="close" (click)="onClose()"></button>
    </header>
    <section class="modal-card-body">
      <form [formGroup]="form">
        <div class="field">
          <label class="label" translate="YOUR_EMAIL"></label>
          <div class="control">
            <input class="input" [class.is-danger]="form.get('email')?.invalid && form.get('email')?.value"
                   type="text" placeholder="john.doe@gmail.com" formControlName="email">
          </div>
        </div>
        <div class="field">
          <label class="label" translate="SUBJECT"></label>
          <div class="control">
            <input class="input" [class.is-danger]="form.get('subject')?.invalid && form.get('subject')?.value"
                   type="text" placeholder="..." formControlName="subject">
          </div>
        </div>
        <div class="field">
          <label class="label" translate="CONTENT"></label>
          <div class="control">
            <textarea class="textarea" [class.is-danger]="form.get('content')?.invalid && form.get('content')?.value"
                      placeholder="..." formControlName="content"></textarea>
          </div>
        </div>
      </form>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-info" (click)="validate()" [class.is-loading]="isSendingMail" [disabled]="form.invalid || isSendingMail" translate="SEND"></button>
      <button class="button" (click)="onClose()" [disabled]="isSendingMail" translate="CANCEL"></button>
    </footer>
  </div>
</div>
