<div class="level-item has-text-centered card p-3" [class.has-ribbon]="ribbon" [class.is-mobile]="utilsService.isMobile">
    <div *ngIf="ribbon" class="ribbon" [ngClass]="ribbonColor">{{ribbon}}</div>
    <div class="card-image" [class.mt-5]="utilsService.isMobile && ribbon">
        <figure class="image">
            <img [src]="imageUrl">
        </figure>
    </div>
    <div class="card-content" [class.mt-4]="ribbon">
        <h1 class="has-text-weight-bold">{{title}}</h1>
    </div>
</div>
