<div class="level-item has-text-centered card" [class.p-3]="!utils.isMobile" [id]="experience.id">
  <div class="card-content">
    <h2 class="has-text-weight-bold">{{experience.poste}}</h2>
    <h3 class="mt-0">{{experience.entreprise}}</h3>
    <h4 class="is-italic">{{experience.dates}}</h4>
    <article class="message" *ngFor="let mission of experience.missions">
      <div class="message-header" [class.mobile-mission-header]="utils.isMobile">
        <span class="message-header__title">{{mission.titre}}</span>
        <span>{{mission.dates}}</span>
      </div>
      <div class="message-body has-text-justified">
        <p>{{mission.description}}</p>
        <div class="technologies">
          <div *ngIf="mission.technologiesUsed.langages">
            <span>{{'LANGUAGES' | translate}} :</span>
            <span class="tag is-dark" *ngFor="let langage of mission.technologiesUsed.langages">{{langage}}</span>
          </div>
          <div *ngIf="mission.technologiesUsed.frameworks">
            <span>{{'FRAMEWORKS' | translate}} :</span>
            <span class="tag is-dark" *ngFor="let framework of mission.technologiesUsed.frameworks">{{framework}}</span>
          </div>
          <div *ngIf="mission.technologiesUsed.outils">
            <span>{{'TOOLS' | translate}} :</span>
            <span class="tag is-dark" *ngFor="let outil of mission.technologiesUsed.outils">{{outil}}</span>
          </div>
          <div *ngIf="mission.technologiesUsed.editeurs">
            <span>{{'CODE_EDITORS' | translate}} :</span>
            <span class="tag is-dark" *ngFor="let editeur of mission.technologiesUsed.editeurs">{{editeur}}</span>
          </div>
          <div *ngIf="mission.technologiesUsed.autres">
            <span>{{'OTHERS' | translate}} :</span>
            <span class="tag is-dark" *ngFor="let autre of mission.technologiesUsed.autres">{{autre}}</span>
          </div>
        </div>
      </div>
    </article>
  </div>
</div>
