import {Projet} from "../projet";

export class ProjectsData {
  static FRENCH_PROJECTS: Projet[] = [
    {
      id: 0,
      images: [
        'assets/projets/plutus/login.png',
        'assets/projets/plutus/signup.png',
        'assets/projets/plutus/main.png',
        'assets/projets/plutus/main_details_1.png',
        'assets/projets/plutus/main_details_2.png',
        'assets/projets/plutus/compte.png',
        'assets/projets/plutus/creation_1.png',
        'assets/projets/plutus/creation_2.png',
        'assets/projets/plutus/creation_3.png',
        'assets/projets/plutus/details_1.png',
        'assets/projets/plutus/details_2.png',
        'assets/projets/plutus/tableau.png',
        'assets/projets/plutus/preferences.png',
        'assets/projets/plutus/mobile.png'
      ],
      titre: `Application de gestion d'argent (Plutus)`,
      description: `Application web responsive permettant de gérer ses dépenses. Gestion de l'authentification avec Spring Security, utilisation d'une base de données MongoDB, stockage des fichiers sur Google Cloud Storage et utilisation de l'API Google Maps.`,
      date: 'Février 2023',
      technologiesUsed: {
        langages: ['HTML', 'SCSS', 'JavaScript', 'TypeScript', 'Java'],
        frameworks: ['Angular', 'Angular', 'Spring Boot', 'PrimeNG', 'NgxCharts', 'FontAwesome'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate'],
        autres: ['MongoDB', 'Google Maps API', 'Google Cloud Storage', 'Java Mail']
      },
      documents: [
        {
          nom: 'Démonstration',
          type: 'demo',
          lien: 'http://plutus.theoernould.fr/'
        }
      ]
    },
    {
      id: 1,
      image: 'assets/projets/arbscanner.png',
      titre: 'Détecteur d\'opportunités d\'arbitrage',
      description: 'Interface web permettant de détecter les opportunités de spreading sur le marchés des cryptomonnaies. Le spreading consiste à acquérir un actif sur une plateforme et à le revendre aussitôt sur une autre plateforme à un prix plus élevé afin de réaliser un bénéfice. L\'utilisateur pouvant sélectionner les cryptomonnaies (BTC, ETH, ...) et les plateformes d\'échanges (Binance, Coinbase, ...) et ainsi obtenir une liste des opportunités de spreading.',
      date: 'Septembre 2022',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'JavaScript', 'TypeScript', 'Java'],
        frameworks: ['Angular', 'Angular Material', 'Spring Boot'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: []
    },
    {
      id:2,
      image: 'assets/projets/hermes-chat.png',
      titre: 'Chat sécurisé',
      description: 'Application de chat sécurisé avec chiffrement.',
      date: 'Mars 2022',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript', 'NodeJS'],
        frameworks: ['ExpressJS', 'Socket.io'],
        outils: ['Git', 'npm'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/hermes-website',
        },
        {
          nom: 'Compte-rendu',
          type: 'download',
          lien: 'assets/projets/compte-rendu.pdf',
          downloadName: 'Compte-rendu.pdf'
        }
      ]
    },
    {
      id: 3,
      video: 'https://www.youtube.com/embed/nOYWxa42C5g',
      titre: 'Logiciel de visualisation 3D',
      description: 'Logiciel de visualisation 3D développé en groupe dans le cadre du DUT Informatique. Le but étant de pouvoir visualiser un fichier .ply et d\'y appliquer des transformations géométriques comme la rotation, l\'homothétie, le calcul des ombres, le lissage ou encore le zoom.',
      date: 'Janvier 2022',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/Visualisateur-3D',
        },
        {
          nom: 'Application',
          type: 'download',
          lien: 'assets/projets/Projet Modélisation.jar',
          downloadName: 'Projet Modélisation.jar'
        }
      ]
    },
    {
      id: 4,
      image: 'assets/projets/jeu_moulins.png',
      titre: 'Jeu du moulin',
      description: 'Jeu du moulin développé en groupe dans le cadre du DUT Informatique sous la forme d\'un logiciel. Le but étant de pouvoir jouer au jeu du moulin à deux sur un même ordinateur.',
      date: 'Juin 2021',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/Jeu-Du-Moulin',
        },
        {
          nom: 'Application',
          type: 'download',
          lien: 'assets/projets/Jeu du moulin.jar',
          downloadName: 'Jeu du moulin.jar'
        }
      ]
    },
    {
      id: 5,
      image: 'assets/projets/convertisseur.png',
      titre: 'Convertisseur de couleurs',
      description: 'Convertisseur de couleurs développé en groupe pendant mon DUT Informatique. L\'objectif est d\'obtenir des teintes de couleur (visibles les unes par par rapport aux autres en nuance de gris) en fonction d\'une couleur donnée.',
      date: 'Juin 2021',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/convertisseur-couleurs',
        }
      ]
    },
    {
      id: 6,
      image: 'assets/projets/bataille-navale.png',
      titre: 'Bataille navale',
      description: 'Le fameux jeu de stratégie recréé en site web dans le cadre de la matière Informatique et Science du Numérique (ISN) en classe Terminale',
      date: 'Octobre 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript', 'jQuery'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/bataille-navale',
        },
        {
          nom: 'Démo',
          type: 'demo',
          lien: 'https://theoernould.github.io/bataille-navale/',
        }
      ]
    },
    {
      id: 7,
      image: 'assets/projets/qui-est-ce.png',
      titre: 'Qui est-ce ?',
      description: 'Le jeu iconique créé sous la forme d\'un site web dans le cadre de la matière Informatique et Science du Numérique (ISN) en classe de Terminale',
      date: 'Octobre 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/qui-est-ce',
        },
        {
          nom: 'Démo',
          type: 'demo',
          lien: 'https://theoernould.github.io/qui-est-ce/',
        }
      ]
    },
    {
      id: 8,
      image: 'assets/projets/site-tpe.png',
      titre: 'Site TPE',
      description: 'Site web réalisé dans le cadre de mon TPE (Travaux Personnels Encadrés) en classe de Première au Lycée, servant de support pour la présentation de notre sujet, en l\'occurence le rôle des rayonnements ionisants dans les mutations génétiques.',
      date: 'Février 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'Projet Github',
          type: 'github',
          lien: 'https://github.com/theoernould/tpe',
        },
        {
          nom: 'Démo',
          type: 'demo',
          lien: 'https://theoernould.github.io/tpe/',
        }
      ]
    },
  ];

  static ENGLISH_PROJECTS: Projet[] = [
    {
      id: 0,
      images: [
        'assets/projets/plutus/login.png',
        'assets/projets/plutus/signup.png',
        'assets/projets/plutus/main.png',
        'assets/projets/plutus/main_details_1.png',
        'assets/projets/plutus/main_details_2.png',
        'assets/projets/plutus/compte.png',
        'assets/projets/plutus/creation_1.png',
        'assets/projets/plutus/creation_2.png',
        'assets/projets/plutus/creation_3.png',
        'assets/projets/plutus/details_1.png',
        'assets/projets/plutus/details_2.png',
        'assets/projets/plutus/tableau.png',
        'assets/projets/plutus/preferences.png',
        'assets/projets/plutus/mobile.png'
      ],
      titre: `Money management application (Plutus)`,
      description: `Responsive web application for managing your expenses. Authentication management with Spring Security, use of a MongoDB database, file storage on Google Cloud Storage and use of the Google Maps API.`,
      date: 'February 2023',
      technologiesUsed: {
        langages: ['HTML', 'SCSS', 'JavaScript', 'TypeScript', 'Java'],
        frameworks: ['Angular', 'Angular', 'Spring Boot', 'PrimeNG', 'NgxCharts', 'FontAwesome'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate'],
        autres: ['MongoDB', 'Google Maps API', 'Google Cloud Storage', 'Java Mail']
      },
      documents: [
        {
          nom: 'Demo',
          type: 'demo',
          lien: 'http://plutus.theoernould.fr/'
        }
      ]
    },
    {
      id: 1,
      image: 'assets/projets/arbscanner.png',
      titre: 'Opportunity Arbitrage Detector',
      description: 'Web interface for detecting arbitrage opportunities in the cryptocurrency markets. Arbitrage involves acquiring an asset on one platform and selling it immediately on another platform at a higher price to make a profit. Users can select cryptocurrencies (BTC, ETH, ...) and exchange platforms (Binance, Coinbase, ...) to obtain a list of arbitrage opportunities.',
      date: 'September 2022',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'JavaScript', 'TypeScript', 'Java'],
        frameworks: ['Angular', 'Angular Material', 'Spring Boot'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: []
    },
    {
      id: 2,
      image: 'assets/projets/hermes-chat.png',
      titre: 'Secure Chat',
      description: 'Secure chat application with encryption.',
      date: 'March 2022',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript', 'NodeJS'],
        frameworks: ['ExpressJS', 'Socket.io'],
        outils: ['Git', 'npm'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/hermes-website',
        },
        {
          nom: 'Report',
          type: 'download',
          lien: 'assets/projets/compte-rendu.pdf',
          downloadName: 'Compte-rendu.pdf'
        }
      ]
    },
    {
      id: 3,
      video: 'https://www.youtube.com/embed/nOYWxa42C5g',
      titre: '3D Visualization Software',
      description: '3D visualization software developed in a group as part of the DUT Computer Science program. The goal is to visualize a .ply file and apply geometric transformations such as rotation, scaling, shadow calculation, smoothing, and zoom.',
      date: 'January 2022',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git', 'Maven'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/Visualisateur-3D',
        },
        {
          nom: 'Application',
          type: 'download',
          lien: 'assets/projets/Projet Modélisation.jar',
          downloadName: 'Projet Modélisation.jar'
        }
      ]
    },
    {
      id: 4,
      image: 'assets/projets/jeu_moulins.png',
      titre: 'Mill Game',
      description: 'Mill game developed in a group as part of the DUT Computer Science program in the form of software. The goal is to play the mill game with two players on the same computer.',
      date: 'June 2021',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/Jeu-Du-Moulin',
        },
        {
          nom: 'Application',
          type: 'download',
          lien: 'assets/projets/Jeu du moulin.jar',
          downloadName: 'Jeu du moulin.jar'
        }
      ]
    },
    {
      id: 5,
      image: 'assets/projets/convertisseur.png',
      titre: 'Color Converter',
      description: 'Color converter developed in a group during my DUT Computer Science program. The objective is to obtain shades of color (visually distinguishable from each other in grayscale) based on a given color.',
      date: 'June 2021',
      technologiesUsed: {
        langages: ['Java'],
        frameworks: ['JavaFX'],
        outils: ['Git'],
        editeurs: ['IntelliJ Ultimate']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/convertisseur-couleurs',
        }
      ]
    },
    {
      id: 6,
      image: 'assets/projets/bataille-navale.png',
      titre: 'Battleship',
      description: 'The famous strategy game recreated as a website as part of the Information and Digital Science (ISN) subject in the final year of high school.',
      date: 'October 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript', 'jQuery'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/bataille-navale',
        },
        {
          nom: 'Demo',
          type: 'demo',
          lien: 'https://theoernould.github.io/bataille-navale/',
        }
      ]
    },
    {
      id: 7,
      image: 'assets/projets/qui-est-ce.png',
      titre: 'Guess Who?',
      description: 'The iconic game recreated as a website as part of the Information and Digital Science (ISN) subject in the final year of high school.',
      date: 'October 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/qui-est-ce',
        },
        {
          nom: 'Demo',
          type: 'demo',
          lien: 'https://theoernould.github.io/qui-est-ce/',
        }
      ]
    },
    {
      id: 8,
      image: 'assets/projets/site-tpe.png',
      titre: 'TPE Website',
      description: 'Website created as part of my TPE (Personalized Supervised Work) in the first year of high school, serving as a presentation platform for our subject, namely the role of ionizing radiation in genetic mutations.',
      date: 'February 2019',
      technologiesUsed: {
        langages: ['HTML', 'CSS', 'Javascript'],
        editeurs: ['Visual Studio Code']
      },
      documents: [
        {
          nom: 'GitHub Project',
          type: 'github',
          lien: 'https://github.com/theoernould/tpe',
        },
        {
          nom: 'Demo',
          type: 'demo',
          lien: 'https://theoernould.github.io/tpe/',
        }
      ]
    },
  ];

}
