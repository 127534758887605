import {Component, inject, Input} from '@angular/core';
import {Experience} from "../../../../models/experience";
import {UtilsService} from "../../../../shared/utils.service";

@Component({
  selector: 'app-experience', templateUrl: './experience.component.html', styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent {
  @Input() experience!: Experience;

  utils = inject(UtilsService);

  joinArray(array: string[]): string {
    return array.join(', ');
  }
}
