import {Component, inject} from '@angular/core';
import {ProjectsData} from "../../../models/staticData/projectsData";
import {Projet} from "../../../models/projet";
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-projets',
  templateUrl: './projets.component.html',
  styleUrls: ['./projets.component.scss']
})
export class ProjetsComponent {
  utils = inject(UtilsService);

  get projets(): Projet[] {
    return this.utils.isActiveLanguageFrench ? ProjectsData.FRENCH_PROJECTS : ProjectsData.ENGLISH_PROJECTS;
  }

}
