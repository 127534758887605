import {Component, HostListener, inject, OnInit} from '@angular/core';
import {UtilsService} from "./shared/utils.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CV-Web';

  public utils = inject(UtilsService);
  public translate = inject(TranslateService);

  showIcon: number | boolean = false;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.utils.refreshMobileStatus();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.showIcon = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0 > 10;
  }

  ngOnInit(): void {
    this.utils.refreshMobileStatus();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
