import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContactModalComponent} from "../contact/contact-modal.component";
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-contact-choice',
  templateUrl: './contact-choice.component.html',
  styleUrls: ['./contact-choice.component.scss']
})
export class ContactChoiceComponent {
  constructor(public dialog: MatDialog, public utils: UtilsService, public dialogRef: MatDialogRef<ContactChoiceComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  openFormModal() {
    this.onClose();
    const formDialogRef = this.dialog.open(ContactModalComponent);

    formDialogRef.afterClosed().subscribe(result => {

    });
  }

  openMailModal() {
    this.onClose();
    window.location.href = 'mailto:theo.ernould@outlook.fr';
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
