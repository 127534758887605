import {Component, inject} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent {
  utils = inject(UtilsService);
}
