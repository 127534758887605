<div class="card">
  <div *ngIf="projet.image || projet.video" class="card-image pt-5">
    <figure *ngIf="projet.image" class="image">
      <img src='{{projet.image}}' alt="Image"/>
    </figure>
    <div class="video-wrapper" *ngIf="projet.video">
      <iframe src="https://www.youtube.com/embed/nOYWxa42C5g" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
  <image-slider class="card-image pt-5" *ngIf="projet.images" [images]="projet.images"></image-slider>
  <div class="card-content">
    <div class="content">
      <h2>{{projet.titre}}</h2>
      <h4>{{projet.date}}</h4>
      <p class="projet-description">
        {{projet.description}}
      </p>
      <div class="technologies">
        <div *ngIf="projet.technologiesUsed.langages">
          <span>{{'LANGUAGES' | translate}} :</span>
          <span class="tag is-dark" *ngFor="let langage of projet.technologiesUsed.langages">{{langage}}</span>
        </div>
        <div *ngIf="projet.technologiesUsed.frameworks">
          <span>{{'FRAMEWORKS' | translate}} :</span>
          <span class="tag is-dark" *ngFor="let framework of projet.technologiesUsed.frameworks">{{framework}}</span>
        </div>
        <div *ngIf="projet.technologiesUsed.outils">
          <span>{{'TOOLS' | translate}} :</span>
          <span class="tag is-dark" *ngFor="let outil of projet.technologiesUsed.outils">{{outil}}</span>
        </div>
        <div *ngIf="projet.technologiesUsed.editeurs">
          <span>{{'CODE_EDITORS' | translate}} :</span>
          <span class="tag is-dark" *ngFor="let editeur of projet.technologiesUsed.editeurs">{{editeur}}</span>
        </div>
        <div *ngIf="projet.technologiesUsed.autres">
          <span>{{'OTHERS' | translate}} :</span>
          <span class="tag is-dark" *ngFor="let autre of projet.technologiesUsed.autres">{{autre}}</span>
        </div>
      </div>
    </div>
  </div>
  <footer class="card-footer">
    <a [href]="document.lien" class="card-footer-item has-background-info" target="_blank" *ngFor="let document of projet.documents">
      <span class="mr-3 has-text-weight-bold has-text-white">{{document.nom}}</span>
      <box-icon *ngIf="document.type === 'lien'" color="white" type='solid' name='link-external'></box-icon>
      <box-icon *ngIf="document.type === 'download'" color="white" type='solid' name='download'></box-icon>
      <box-icon *ngIf="document.type === 'gitlab'" color="white" type='logo' name='gitlab'></box-icon>
      <box-icon *ngIf="document.type === 'github'" color="white" type='logo' name='github'></box-icon>
      <box-icon *ngIf="document.type === 'demo'" color="white" name='play-circle'></box-icon>
    </a>
  </footer>
</div>
