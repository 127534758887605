import {inject, Injectable, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class UtilsService implements OnInit {
  public isMobile: boolean = false;
  public translate = inject(TranslateService);

  private birthdate: Date = new Date(2002, 12, 12);

  ngOnInit(): void {
    this.refreshMobileStatus();
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  getActiveLanguage(): string {
    return this.translate.currentLang;
  }

  get isActiveLanguageFrench(): boolean {
    return this.getActiveLanguage() === 'fr';
  }

  get isActiveLanguageEnglish(): boolean {
    return this.getActiveLanguage() === 'en';
  }

  getMyAge(): number {
    return new Date().getUTCFullYear() - this.birthdate.getUTCFullYear();
  }

  refreshMobileStatus() {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  goToTarget(target: string) {
    if(target === 'apropos') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      // @ts-ignore
      document.getElementById(target).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
}
