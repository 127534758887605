import {Component, inject} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {
  public utilsService = inject(UtilsService);
}
